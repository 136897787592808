import React from "react"
import { graphql } from "gatsby"

import SubmitPage from "./index"


// just the submit page but with a modal over it
const SubmitAddPage = SubmitPage;


export const query = graphql`
query {
  allEstimates: allGoogleSpreadsheetSourceEstimates(sort: {fields: order, order: ASC}) {
    totalCount
    nodes {
      status
      cost_Mo
      views__mo
      visitors__mo
      leads__mo
      description
      cpc
      ctr
    }
  }
}
`

export default SubmitAddPage


